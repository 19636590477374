.bearbeiter-auswahl {
    padding: 0;
    width: 100%;
}

.bearbeiter-auswahl > *,
.bearbeiter-auswahl > * > *,
.bearbeiter-auswahl > *::before,
.bearbeiter-auswahl > *.Mui-focused,
.bearbeiter-auswahl > *:hover:not(.Mui-disabled, .Mui-error)::before {
    color: inherit;
    border-color: inherit;
}