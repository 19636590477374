.navigation__drawer-paper {
    width: 280px;
}

.navigation__item {
    padding-left: 2rem;
}

.navigation__toolbar {
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: center;
    height: 50px;
}
