.fotos__aktionen {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.fotos__aktionen > * {
    margin: 1rem;
    width: 200px;
}

.fotos_fotobereich {
    margin-top: 10px;
}

@media only screen and (min-width: 1000px) {
    .fotos__aktionen {
        position: fixed;
        background-color: #fff;
        margin-bottom: 1rem;
        z-index: 1000;
        width: 100%;
        top: 70px;
        padding: 5px 32px;
        left: 0;
        box-shadow:0 -2px 9px rgba(50, 50, 50, 0.5);
        align-items: center;
        justify-content: center;
    }

    .fotos__aktionen > * {
        margin: 0 1rem 0;
    }

    .fotos_fotobereich {
        margin-top: 40px;
    }
}
