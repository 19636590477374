.file-input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    border: 4px dashed grey;
    cursor: pointer;
}

.file-input > input {
    width: 0;
    height: 0;
}

.file-input__icon {
    margin-right: 1rem;
}
