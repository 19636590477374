.vorgang-toolbar {
    display: flex;
    height: 70px;
}

.vorgang-toolbar__titel {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.vorgang-toolbar__titel > * {
    margin: 0 5px;
}

.vorgang-toolbar__vorgangsnummer {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    width: 300px;
}

.vorgang-toolbar__info {
    font-size: 12px;
    width: 300px;
}

.vorgang-toolbar__info > * {
    margin-left: 5px;
}

.vorgang-toolbar__auswahl {
    padding: 0;
    width: 8rem;
}

.vorgang-toolbar__link {
    color: inherit;
    display: flex;
}

.vorgang-toolbar__status,
.vorgang-toolbar__auswahl > *,
.vorgang-toolbar__auswahl > * > *,
.vorgang-toolbar__auswahl > *::before,
.vorgang-toolbar__auswahl > *.Mui-focused,
.vorgang-toolbar__auswahl > *:hover:not(.Mui-disabled, .Mui-error)::before {
    color: inherit;
    border-color: inherit;
    -webkit-text-fill-color: inherit;
}

.vorgang-toolbar__status__dat-sync {
    color: inherit;
    border-color: inherit;
    margin: 0 5px;
    font-size: 0.8rem;
    line-height: 1.6;
}

.vorgang-toolbar__status__dat-sync--offen > span {
    background-color: #fff;
}

.vorgang-toolbar__dialog {
    position: absolute;
    right: 1rem;
    top: 1rem;
}

@media only screen and (max-width: 1200px) {
    .vorgang-toolbar__vorgangsnummer {
        font-size: 0.8rem;
        width: unset;
    }

    .vorgang-toolbar__status__dat-sync {
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .vorgang-toolbar__titel {
        flex-wrap: wrap;
        justify-content: center;
    }

    .vorgang-toolbar__link,
    .vorgang-toolbar__auswahl  {
        display: none;
    }
}
