.signature__skeleton {
  max-width: 600px;
  height: 200px;
  display: block;
  border-radius: 15px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 166, 81, 0.5) 50%, rgba(255, 255, 255, 0) 80%), rgb(255, 255, 255);
  background-repeat: repeat-y;
  background-size: 10px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
  border: 3px dashed #9e9e9e;
}
@keyframes shine {
  to {
    background-position:
      100% 0,
      0 0;
  }
}

.unterschriften__modal-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
}
