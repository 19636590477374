.layout {
    margin-bottom: 3rem;
}

#layout__app-toolbar {
    width: 100%;
    color: white;
}

.layout__content {
    flex-grow: 1;
    padding: 5rem 2rem 2rem;
}
