.tui-image-editor-header {
  display: none;
}

.tui-image-editor-main {
  position: unset !important;
}

.tui-image-editor-container.bottom .tui-image-editor-controls {
  width: 100%;
  display: list-item;
  overflow-x: scroll; /* broken tooltips*/
  overflow-y: hidden;
  left: 0;
}
