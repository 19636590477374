.bottom-navigation {
    bottom: 0;
    box-shadow: 0 -2px 9px rgba(50, 50, 50, 0.5);
    display: flex;
    width: 100%;
    position: fixed;
    margin-left: -31px;
    justify-content: space-between;
    overflow: auto;
}

.bottom-navigation > * {
    margin-right: 1rem;
}
.bottom-navigation__button--red {
    color: red;
}

.bottom-navigation__button--yellow {
    color: #ff9900;
}
